@import '../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    animation: login 0.6s ease forwards;
    @include animation-mixin-y(login, 60px, 0px);
    width: 80%;
    max-width: 380px;
    min-height: 100%;
    padding: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .logo {
      display: flex;
      justify-content: center;
    }
    .btn-container {
      margin: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      &.reset {
        justify-content: center;
        a {
          text-decoration: none;
        }
      }
      .forgot {
        @include btn;
        @include label;
        padding: 0px;
        margin-top: 10px;
        color:rgba($dark , 0.4);
        text-decoration: underline;
        &:hover {
          color: $dark
        }
      }
    }
    .message {
      min-height: 36px;
      text-align: center;
      color: $primary-color;
      .error {
        color: $danger;
      }
    }
  }
}