@import '../../styles/variables';

.container {
  @include transition;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 100%;
  overflow: auto;
  background-color: $bg-color;
  z-index: 100;
  transform: translateX(100%);
  box-shadow: $shadow;
  @media only screen and (min-width: $lg-screen) {
    width: 45%;
  }
  &.active {
    transform: translateX(0);
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    @include btn;
    font-size: 35px;
  }
  .content {
    padding: 4px 20px;
    .title {
      padding: 20px 0px;
      h3 {
        margin: 0px;
        font-size: 26px;
        color: rgba($dark, 0.5);
      }
      .dates {
        display: flex;
        justify-content: flex-start;
        padding: 15px 0px 0px 0px;
        .author {
          @include bold;
          margin: 0px;
        }
        .date {
          display: flex;
          align-items: center;
          color: rgba($dark, 0.7);
          margin-right: 15px;
          svg {
            font-size: 18px;
            margin-right: 10px;
          }
          p {
            margin: 0px;
          }
        }
      }
      border-bottom: 1px solid rgba($dark, 0.4);
    }
    .image {
      margin-top: 20px;
      height: 220px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: $radius;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .text {
      margin: 20px 0px;
    }
  }
}