@import '../../styles/variables.scss';

.container {
  width: 100%;
  .input {
    display: flex;
    justify-content: flex-start;
    label {
      position: relative;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      @include btn;
      background-color: $primary-color;
      @include text;
      padding: 10px 20px;
      box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.14);
      border-radius: 5px;
      &.label {
        color: $white;
      } 
    }
    input {
      position: absolute;
      display: none;
      opacity: 0;
      padding: 0px;
    }
  }
}