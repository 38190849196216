@import '../../styles/variables';

.container {
  width: 100%;
  .list {
    margin-top: 20px;
    .step {
      border: 1px solid rgba($dark, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      border-radius: 5px;
      height: 60px;
      overflow: hidden;
      &.active {
        justify-content: flex-start;
        .bar {
          height: 100%;
          width: 15px;
          margin-right: 10px;
        }
        img {
          width: 20px;
          margin-right: 10px;
        }
        p {
          &:last-child {
            opacity: 0.5;
            margin-left: 5px;
          }
        }
      }
    }
  }
}