@import '../../styles/variables.scss';

.container {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(80vh - 40px);
  @include scrollbar;
  min-width: calc(100% - 40px);
  @media only screen and (min-width: $md-screen) {
    min-width: 600px;
  }
  h2 {
    margin: 0px 0px 20px 0px;
  }
  label {
    @include label;
    color: rgba($dark, 0.7);
  }
  .row {
    display: flex;
    justify-content: space-between;
    .input-block {
      width: calc(50% - 10px);
    }
    .input-state {
      width: calc(80% - 10px);
      margin-bottom: 10px;
      .input {
        margin-top: 5px; 
      }
    }
    .state {
      height: 38px;
      width: 20%;
      margin-left: 8px;
      margin-top: 23px;
      border-radius: 5px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      &.todo {
        background-color: rgba($dark, 0.25);
      }
      &.doing {
        background-color: #FFA56D;
      }
      &.done {
        background-color: $success;
      }
    }
  }
  .input-block {
    margin-bottom: 10px;
    .input {  
      margin-top: 5px;
    }
    .checkbox {
      margin-top: 16px;
      display: flex;
      .label {
        margin-top: 1.5px;
        margin-left: 10px;
      }
    }
    .priorities {
      margin-top: 5px;
      button {
        @include btn;
        @include text;
        width: 78px;
        height: 36px;
        margin-right: 10px;
        color: $white;
        border-radius: $radius;
        background-color: rgba($primary-color, 0.3);
        &.hight {
          background-color: $danger;
          opacity: 0.3;
          &:hover {
            opacity: 0.8;
          }
          &.active {
            opacity: 1;
          }
        }
        &.medium {
          background-color: #fb9b60;
          opacity: 0.3;
          &:hover {
            opacity: 0.8;
          }
          &.active {
            opacity: 1;
          }
        }
        &.low {
          background-color: #ffd16d;
          opacity: 0.3;
          &:hover {
            opacity: 0.8;
          }
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
  .section {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #EDECF4;
    h3 {
      margin: 0px 0px 10px;
    }
  }
  .list {
    margin: 20px 0px;
    .item {
      @include transition;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0px 0px 6px 0px;
      padding: 6px 12px;
      background-color: rgba($primary-color, 0.16);
      border-radius: 8px;
      .col2 {
        width: 90%;
        display: flex;
        p {
          width: 50%;
        }
      }
      p {
        margin: 0px;
      }
      button {
        @include btn;
        color: rgba($primary-color, 0.4);
        &:hover {
          color: $danger;
        }
      }
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    button {
      @include btn;
      @include text-button;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border : none;
      position: relative;
      color: $white;
      background: $primary-color;
      line-height: 29px;
      border-radius: $radius;
      letter-spacing: 1px;
      cursor: pointer;
      min-width: 155px;
      padding: 6px 20px;
      &:hover {
        background-color: $success;
      }
      &.delete {
        background-color: transparent;
        color: $danger;
        &:hover {
          background-color: $danger;
          color: $white;
        }
      }
    }
  }
}