@import '../../styles/variables';

@mixin day {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  opacity: 1;
  .toggle {
    @include btn;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    text-transform: uppercase;
    p {
      @include title-2;
      margin: 0px 10px 0px 0px;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .select {
    @include transition;
    position: absolute;
    top: 30px;
    z-index: 200;
    opacity: 0;
    pointer-events: none;
    background-color: $white;
    width: 260px;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 48px -4px rgba(24, 39, 75, 0.14);
    border-radius: 10px;
    padding: 10px;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0px;
        @include title-2;
        font-size: 18px;

      }
      button {
        @include btn;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        border-radius: 50%;
        &:hover {
          background-color: rgba($dark, 0.1);
        }
      }
    }
    .options {
      margin-top: 10px;
      padding: 5px 10px;
      .week {
        margin-bottom: 5px;
        @include bold;
        color: rgba($dark, 0.5);
        display: flex;
        justify-content: space-between;
        .day {
          @include day;
        }
      }
      button {
        width: 100%;
        @include btn;
        @include bold;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        .day {
          @include day;
          &:hover {
            background-color: rgba($dark, 0.1);
          }
        }
      }
    }

  }
}
