@import '../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  padding: 0px;
  overflow: hidden;
  h2 {
    @include title-2;
    font-size: 20px;
    margin: 0px;
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
      margin: 20px;
    }
  }
  .header {
    padding: 20px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    width: 100%;
    @media only screen and (min-width: $md-screen) {
      position: absolute;
      padding: 0px 30px 0px 15.5%;
      top: 15px;
    }
    @media only screen and (min-width: $md-screen) {
      position: absolute;
      padding: 0px 30px 0px 15.5%;
      top: 15px;
    }
    .actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      img {
        margin-right: 10px;
      }
      .view {
        display: none;
        @media only screen and (min-width: $xl-screen) {
          display: flex;
          align-items: center;
          margin-right: 8px;
        }
        button {
          @include btn;
          @include title-2;
          font-size: 14px;
          text-transform: uppercase;
          margin-left: 10px;
          opacity: 0.4;
          &.active {
            opacity: 1;
          }
          &.cr {
            background-color: $dark;
            padding: 5px 10px;
            border-radius: 5px;
            color: $white;
            opacity: 1;
          }
        }
      }
      .nav {
        display: flex;
        align-items: center;
        margin-top: 4px;
        .date {
          display: none;
          align-items: center;
          margin-right: 10px;
          p {
            color: rgba($dark, 0.4);
            margin: 0px;
          }
          @media only screen and (min-width: $sm-screen) {
            display: flex;
            align-items: center;
          }
        }
        button {
          @include btn;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          border-radius: 50%;
          &:hover {
            background-color: rgba($dark, 0.1);
          }
        }
      }
    }
  }
  .planning {
    width: 100%;
    height: calc(100% - 70px);
    top: 70px;
    overflow: auto;
    position: absolute;
    // bottom:0px;
    .content {
      width: 180%;
      padding: 0px 30px;
      @media only screen and (min-width: $sm-screen) {
        width: 160%;
      }
      @media only screen and (min-width: $md-screen) {
        padding: 0px 30px 0px 0px;
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}