@import '../../styles/variables.scss';

.container {
  @include page-container;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  h2 {
    @include title-2;
    font-size: 20px;
    padding: 20px;
    margin: 0px;
    @media only screen and (min-width: $lg-screen) {
      padding: 20px 40px;
    }
  }
  main {
    @include transition;
    width: 100%;
    @media only screen and (min-width: $lg-screen) {
      width: 65%;
    }
    .todo {
      @include btn;
      @include title-2;
      position: absolute;
      font-size: 16px;
      top: 20px;
      right: 20px;
      background-color: $dark;
      color: $white;
      padding: 8px 15px;
      border-radius: 6px;
    }
    .list {
      position: relative;
      padding: 0px 20px 20px 20px;
      width: 100%;
      max-height: calc(100vh - 114px);
      overflow-y: auto;
      @include scrollbar;
      z-index: 3;
      @media only screen and (min-width: $lg-screen) {
        padding: 5px 40px;
        max-height: calc(100vh - 120px);
      }
    }
    .row {
      width: 100%;
      display: flex;
      text-decoration: none;
      &.labels {
        padding: 5px 20px;
        @media only screen and (min-width: $lg-screen) {
          padding: 5px 40px;
        }
        .col {
          margin: 0px;
        }
      }
      &:not(.labels) {
        @include btn;
        @include box-static;
        min-height: 60px;
        &.active {
          background-color: $dark;
          p {
            color: $white;
            &.name {
              color: $white;
            }
          }
        }
      }
      .col {
        display: flex;
        align-items: center;
        width: 60%;
        padding: 0px 10px;
        margin: 8px 0px;
        color:rgba(77, 78, 82, 1);
        @media only screen and (min-width: $lg-screen) {
          width: calc((100% - 40px) / 3 );
        }
        &.desktop {
          display: none;
          @media only screen and (min-width: $lg-screen) {
            display: flex;
          }
        }
        .icon {
          width: 25px;
          height: 25px;
          border-radius: 15px;
          margin-left: 5px;
        }
        p {
          font-weight: 500;
          margin:0px;
          padding: 0px 10px;
          word-break:keep-all;
          &.name {
            @include bold;
          }
        }
      }
      .edit {
        display: none;
        @media only screen and (min-width: 1300px) {
          display: flex;
          width: 40px;
          img {
            width: 20px;
          }
        }
      }
    }
  }
  aside {
    @include transition;
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: $dark;
    transform: translateX(100vw);
    z-index: 10;
    right: 0px;
    @media only screen and (min-width: $lg-screen) {
      width: 35vw;
      transform: translateX(0); 
    }    
    &.active {
      transform: translateX(0);    
    }
    h3 {
      @include title-2;
      color: $white;
      margin: 0px;
    }

  }
}