@import '../../styles/variables';

.container {
  @include transition;
  position: fixed;
  right: 0px;
  height: calc(100vh - 72px);
  top: 72px;
  width: 100%;
  overflow: auto;
  background-color: $bg-color;
  z-index: 102;
  transform: translateX(100%);
  @media only screen and (min-width: $lg-screen) {
    width: calc(100% - 369px);
    z-index: 99;
  }
  &.active {
    transform: translateX(0);
    @media only screen and (min-width: $lg-screen) {
      transform: translateX(-369px);
      border-top: 1px solid rgba($dark, 0.2);
    }
  }
  .content {
    padding: 4px 20px;
    .header {
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: $lg-screen) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .left {
        display: flex;
        width: 50%;
        .title {
          display: flex;
          align-items: center;
          margin-left: 10px;
          width: 100%;
          h3 {
            @include title-2;
            font-size: 22px;
            text-transform: uppercase;
            color: $dark;
          }
        }
      }
      .edit {
        width: 39px;
        position: absolute;
        right: 20px;
        @media only screen and (min-width: $lg-screen) {
          position: relative;
          right: 0px;
        }
      }
      .close {
        @include btn;
        font-size: 35px;
      }
      h3 {
        margin: 0px;
        font-size: 26px;
        color: rgba($dark, 0.5);
      }
      p {
        @include title-2;
        font-size: 18px;
        text-transform: capitalize;
        font-weight: bold;
        color: $dark;
        @media only screen and (min-width: $lg-screen) {
          margin: 0px;
        }
      }
      button {
        @include btn;
        @include text;
        text-decoration: underline;
        &.delete {
          margin-left: 10px;
          color: $danger;
        }
      }
      // border-bottom: 1px solid rgba($dark, 0.2);
    }
    .cr {
      @media only screen and (min-width: $lg-screen) {
        padding: 40px;
      }
      .infos {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba($dark, 0.2);
        .row {
          @media only screen and (min-width: $lg-screen) {
            display: flex;
            justify-content: space-between;
          }
          .col {
            &:first-child {
              @media only screen and (min-width: $lg-screen) {
                width: calc(50%);
                border-right: 1px solid rgba($dark, 0.2);
                padding-right: 20px;
              }
            }
            .field {
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              align-items: center;
              @include title-2;
              font-size: 16px;
              label {
                width: 160px;
                display: block;
                color: rgba($dark, 0.4);
              }
              p {
                margin: 0px 0px 0px 10px;
              }
            }
            @media only screen and (min-width: $lg-screen) {
              width: calc(50% - 20px);
            }
          }
        }
      }
      .block {
        .label {
          @include title-2;
          font-size: 16px;
        }
        .files {
          .file {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 20px;
            }
            @media only screen and (min-width: $lg-screen) {
              width: 50%;
            }
            a {
              color: $dark;
              svg {
                font-size: 26px;
              }
              display: flex;
              align-items: center;
            }
            p {
              margin: 0px 10px;
            }
            button {
              @include btn;
              font-size: 20px;
              color: $danger;
            }
          }
        }
      }
      .actions {
        float: right;
        .delete {
          @include btn;
          @include text;
          padding: 8px 15px;
          color: $danger;
          text-decoration: underline;
        }
        .submit {
          @include btn;
          @include text;
          background-color: $dark;
          color: $white;
          padding: 8px 15px;
          text-transform: capitalize;
          border-radius: 5px;
        }
      }
    }
  }
}