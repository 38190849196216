@import '../../styles/variables';

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 12px 0px 12px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba($dark, 0.1);
  @media only screen and (min-width: $lg-screen) {
    display: flex;
    align-items: center;
    margin: 10px 30px 0px 30px;
  }
  .menu {
    @media only screen and (min-width: $lg-screen) {
      display: flex;
      align-items: center;
    }
  }
  .notifs {
    position: absolute;
    right: 60px;
    top: 10px;
    font-size: 27px;
    color: $dark;
    @media only screen and (min-width: $lg-screen) {
      display: none;
    }
  }
  .new {
    @include transition;
    position: absolute;
    z-index: 10;
    right: 76px;
    top: 24px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #B55555;
    transform: scale(0);
    @media only screen and (min-width: $lg-screen) {
      display: none;
    }
    &.active {
      transform: scale(1);
    }
  }
  .panel {
    @include transition;
    position: fixed;
    background-color: $bg-color;
    height: calc(100% - 61px);
    width: 100%;
    z-index: 300;
    padding: 20px;
    left: 0px;
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    @media only screen and (min-width: $lg-screen) {
      transform: translateX(0px);
      position: relative;
      margin-left: 10px;
      ul {
        display: flex;
      }
    }
    &.active {
      transform: translateX(0px);
    }
    ul {
      list-style: none;
      margin: 20px 0px 0px 0px;
      padding: 0px;
      @media only screen and (min-width: $lg-screen) {
        margin: 0px 0px 0px 0px;
      }
      li {
        a {
          @include title-3;
          display: block;
          text-decoration: none;
          text-transform: uppercase;
          color: $dark;
          opacity: 0.4;
          font-size: 24px;
          margin-bottom: 25px;
          @media only screen and (min-width: $lg-screen) {
            margin-bottom: 0px;
            margin-right: 15px;
            font-size: 16px;
          }
          &.active-link {
            opacity: 1;
          }
        }
      }
    }
    .tools {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-top: 1px solid rgba($dark, 0.1);
      padding-top: 20px;
      @media only screen and (min-width: $lg-screen) {
        display: none;
      }
      a {
        width: calc(50% - 10px);
        margin-bottom: 10px;
        @include tool;
      }
    }
  }
  .profile {
    &.mobile {
      display: flex;
      align-items: center;
      @media only screen and (min-width: $lg-screen) {
        display: none;
      }
    }
    &.desktop {
      display: none;
      @media only screen and (min-width: $lg-screen) {
        display: flex;
        align-items: center;
      }
    }
    .logout {
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      font-size: 20px;
      border-radius: 15px;
      margin-left: 10px;
      &:hover {
        background-color: rgba($dark, 0.2);
      }
    }
  }
}

.toggle {
  @include btn;
  width: 26px;
  height: 20px;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  transform: translateY(-5px);
  @media only screen and (min-width: $lg-screen) {
    display: none;
  }
  span {
    background-color: black;
    height: 3px;
    position: absolute;
    width: 100%;
    border-radius: $radius;
    left: 0;
    transition: all 0.3s ease;
    &:first-child {
      top: 0;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:last-child {
      top: 16px;
      left: 40%;
      width: 60%;
    }
  }
  &.active {
    span {
      top: 8px;
      &:nth-child(2) {
        opacity: 0;
      }
      &:first-child {
        transform: rotate(45deg);
      }
      &:last-child {
        transform: rotate(-45deg);
        left: 0px;
        width: 100%;
      }
    }
  }
}