@import '../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 100vh;
    @media only screen and (min-width: $lg-screen) {
      padding: 30px;
    }
  h2 {
    @include title-2;
    font-size: 20px;
    margin: 0px 0px 20px 0px;
  }
  h3 {
    margin: 0px;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba($dark, 0.6);
    &.new {
      color: #B55555;
    }
  }
  .row {
    width: 100%;
    @media only screen and (min-width: $lg-screen) {
      padding: 30px;
      display: flex;
      justify-content: center;
      min-height: 90%;
    }
    .col {
      margin-bottom: 20px;
      @media only screen and (min-width: $lg-screen) {
        width: calc(50% - 30px);
        padding: 0px 20px;
        &:first-child {
          border-right: 1px solid rgba($dark, 0.1);
        }
      }
      p {
        color: rgba($dark, 0.6);
      }
    }
  }
  
}