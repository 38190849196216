@import '../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  padding: 20px;
    @media only screen and (min-width: $lg-screen) {
      padding: 30px;
    }
  h2 {
    @include title-2;
    font-size: 20px;
    margin: 0px;
  }
  h3 {
    margin: 20px 0px 0px 0px;
    font-size: 14px;
    text-transform: uppercase;
    &.congress {
      color: #4F3CC8;
    }
    &.plans {
      color: #2197d6;
    }
    &.news {
      color: #11B1C7;
    }
  }
  .cards {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    @media only screen and (min-width: $sm-screen) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }
    @media only screen and (min-width: $lg-screen) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }
  }
  
}