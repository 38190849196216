@import '../../styles/variables';

.container {
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  background-color: $bg-color;
  .user {
    width: 16%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $bg-color;
    z-index: 16;
    p {
      margin: 0px;
      font-size: 13px;
    }
  }
  &.active {
    position: sticky;
    z-index: 16;
    top: 0;
    left: 0;
    margin: 0px 0px 20px;
    background-color: $bg-color;
    .user {
      z-index: 15;
      p {
        @include title-1;
        font-size: 14px;
        transform: translateY(20px);
      }
    }
    .days {
      padding: 20px 0px;
      border-bottom: 1px solid rgba($dark, 0.2);
    }
  }
  .days {
    width: calc(100% - 16%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .auth {
      @include btn;
      @include text;
      position: relative;
      width: calc(90% / 7);
      z-index: 14;
      &.isMonth {
        width: calc(90% / 32);
        &.is-weekend {
          width: calc(90% / 24);
        }
      }
      &.d-none {
        display: none;
      }
      &.no-event {
        cursor: not-allowed;
        .step {
          cursor: not-allowed;
        }
        &:hover {
          .day {
            opacity: 0.4;
            transform: scale(1);
          }
        }
      }
      &.is-weekend {
        width: calc(90% / 5);
      }
      &.is-mobile {
        width: calc(90% / 7);
      }
      .day {
        @include transition;
        opacity: 0.4;
        p {
          margin: 0px;
          &:last-child {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
      }
      .step {
        width: 100%;
        height: 34px;
        cursor: pointer;
      }
      &.active {
        .day {
          opacity: 1;
          transform: scale(1.08);
        }
      }
      &:hover {
        .day {
          opacity: 1;
          transform: scale(1.08);
        }
      }
    }
    .step {
      @include btn;
      @include text;
      position: relative;
      width: calc(90% / 7);
      border-radius: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $office-color;
      background-color: $office-color;
      cursor: default;
      img {
        max-width: 18px;
      }
      .cr {
        position: absolute;
        width: 7px;
        height: 7px;
        bottom: 13px;
        right: 10px;
        background-color: $white;
        border-radius: 5px;
      }
      &.d-none {
        display: none;
      }
      &.isMonth {
        width: calc(90% / 32);
        &.is-weekend {
          width: calc(90% / 24);
        }
      }
      &.is-mobile {
        width: calc(90% / 7);
        &.is-weekend {
          width: calc(90% / 10);
        }
      }
      &.all {
        height: 48px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .cr {
          bottom: 13px;
        }
      }
      &.times {
        position: relative;
        border: 1px solid transparent;
        background-color: transparent;
        .time {
          position: relative;
          width: 100%;
          height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $office-color;
          background-color: $office-color;
          img {
            max-width: 12px;
          }
          .cr {
            bottom: 2px;
            right: 10px
          }
          &:first-child {
            border-top-left-radius: 18px;
            border-top-right-radius: 18px;
          }
          &:last-child {
            border-bottom-left-radius: 18px;
            border-bottom-right-radius: 18px;
          }
          &.weekend {
            border: 1px solid rgba($dark, 0.08);
            background-color: rgba($dark, 0.08);
            color: rgba($dark, 0.2);
          }
          &.training {
            border: 1px solid $training-color;
            background-color: $training-color;
            color: $white;
          }
          &.outdoor_training {
            border: 1px solid $training-color;
            background-color: $training-color;
            color: $white;
          }
          &.congress {
            border: 1px solid $congress-color;
            background-color: $congress-color;
            color: $white;
          }
          &.trips {
            border: 1px solid $trips-color;
            background-color: $trips-color;
            color: $white;
          }
          &.field {
            border: 1px solid $field-color;
            background-color: $field-color;
            color: $white;
          }
          &.sick {
            border: 1px solid $sick-color;
            background-color: $sick-color;
            color: $white;
          }
          &.off {
            color: rgba($dark, 0.4);
            background-color: $white;
            border: 1px solid rgba($dark, 0.4);
          }
        }
      }
      &.is-user {
        height: 34px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.times {
          .cr {
            bottom: 3px;
            right: 10px
          }
          .time {
            height: 17px;
          }
        }
      }
      &.weekend {
        border: 1px solid rgba($dark, 0.08);
        background-color: rgba($dark, 0.08);
        color: rgba($dark, 0.2);
      }
      &.training {
        border: 1px solid $training-color;
        background-color: $training-color;
        color: $white;
      }
      &.outdoor_training {
        border: 1px solid $training-color;
        background-color: $training-color;
        color: $white;
      }
      &.congress {
        border: 1px solid $congress-color;
        background-color: $congress-color;
        color: $white;
      }
      &.trips {
        border: 1px solid $trips-color;
        background-color: $trips-color;
        color: $white;
      }
      &.field {
        border: 1px solid $field-color;
        background-color: $field-color;
        color: $white;
      }
      &.sick {
        border: 1px solid $sick-color;
        background-color: $sick-color;
        color: $white;
      }
      &.off {
        color: rgba($dark, 0.4);
        background-color: $white;
        border: 1px solid rgba($dark, 0.4);
      }
    }
  }
}

.modal {
  background-color: $white;
  min-width: 90vw;
  width: 0px;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 48px -4px rgba(24, 39, 75, 0.14);
  border-radius: 18px;
  z-index: 10;
  @media only screen and (min-width: 500px) {
    min-width: 350px;
  }
  .header {
    padding: 18px 20px;
    background-color: rgba($dark, 0.08);
    h1 {
      margin: 0px;
    }
    h4 {
      margin: 8px 0px;
      font-size: 20px;
      color: rgba($dark, 0.4);
    }
    .cr {
      @include btn;
      @include bold;
      font-size: 14px;
      text-align: left;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .options {
    @include transition;
    opacity: 0;
    padding: 5px;
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: auto;
    &.active {
      opacity: 1;
    }
    .option {
      margin: 10px 0px;
      padding: 0px 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      &.disabled {
        opacity: 0.5;
        .times {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      .times {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // @media only screen and (min-width: 500px) {
        //   justify-content: space-between;
        // }
        button {
          @include btn;
          @include text;
          font-size: 14px;
          color: rgba($dark, 0.6);
          border: 1px solid rgba($dark, 0.6);
          padding: 2px 8px;
          margin-bottom: 4px;
          border-radius: 10px;
          margin-right: 10px;
        }
      }
      .label {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 8px 8px 0px;
        .icon {
          display: flex;
          justify-content: center;
        }
        p {
          margin: 0px 5px 0px 0px;
          font-size: 16px;
          color: rgba($dark, 0.6);
        }
      }
    }
  }

}