@import '../../styles/variables';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 16px;
  }
}