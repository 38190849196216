@import '../../styles/variables';

.meteo {
  display: none;
  @media only screen and (min-width: $lg-screen) {
    display: flex;
    justify-content: flex-start;
  }
  p {
    margin: 0px;
  }
  .deg {
    @include title-3;
    line-height: 31px;
    font-size: 39px;
    margin-right: 10px;
  }
  .icon {
    font-size: 44px;
    transform: translate(-7px, -1px);
  }
}