@import '../../styles/variables.scss';

.sort-item {
  @include btn;
  display: flex;
  justify-items: flex-start;
  align-items: center;
  p {
    font-size: 14px;
    text-align: left;
    font-family: "Ubuntu-Regular";
    color: rgba($dark, 0.5);
  }
  .icon {
    width: 24px;
    height: 24px;
    transform: translate(2px , 2px )
  }
}