@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin animation-mixin-x($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateX($from); opacity: 0;}
    100% {transform: translateX($to); opacity: 1;}
  }
}

@mixin animation-scale($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: scale($from); opacity: 0;}
    100% {transform: scale($to); opacity: 1;}
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin animation-fade($name) {
  @keyframes #{$name} {
    0% { opacity: 0;}
    100% { opacity: 1;}
  }
}

@mixin btn {
	cursor: pointer;
  appearance: none;
  padding: 0px;
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  margin: 0px;
  outline: none;
  border: none;
}

@mixin page-container {
  position: relative;
  top: 0;
  width: 100%;
  height: calc(100vh - 70px);
  padding: 20px;
  overflow: auto;
}

@mixin box-static {
  @include transition;
  background: #FFFFFF;
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 3px;
  margin-bottom: 20px;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}

@mixin scrollbarWhite {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($white, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $white;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin tool {
  @include title-3;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $dark;
  text-transform: uppercase;
  .icon {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      @include text;
      font-size: 22px;
    }
  }
}