@import '../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media only screen and (min-width: $lg-screen) {
    padding: 30px;
  }
  .nav {
    .selected {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media only screen and (min-width: $lg-screen) {
        display: none;
      }
      h1 {
        margin: 0px;
        @include title-3;
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        color: $dark;
        font-size: 20px;
        margin-right: 10px;
      }
      button {
        @include btn;
        @include flex-center;
        font-size: 30px;
      }
    }
    .options {
      @include transition;
      height: 0px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: $lg-screen) {
        height: 36px;
        flex-direction: row;
      }
      .opt {
        @include btn;
        @include title-3;
        font-size: 20px;
        text-transform: uppercase;
        text-align: left;
        margin: 8px 0px;
        color: rgba($dark, 0.4);
        padding-bottom: 4px;
        @media only screen and (min-width: $lg-screen) {
          margin-right: 20px;
          height: 26px;
          border-bottom: 2px solid transparent;
        }
        &.active {
          @media only screen and (min-width: $lg-screen) {
            color: $dark;
            border-bottom: 2px solid $dark;
          }
        }
      }
    }
    &.isOpen {
      .selected {
        button {
          transform: rotate(180deg);
        }
      }
      .options {
        height: 140px;
      }
    }
  }
  .cards {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    @media only screen and (min-width: $lg-screen) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }
  }
  
}