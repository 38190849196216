@mixin title-big-1 {
	font-family: "Montserrat-Bold";
  font-weight: normal;
  font-size: 100px;
  line-height: 1.3em;
}

@mixin title-1 {
  font-family: "Anybody-Bold";;
  font-weight: normal;
  font-size: 32px;
}

@mixin title-2 {
  font-family: "Anybody-Bold";
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
}

@mixin title-3 {
  font-family: "Anybody-SemiBold";
  font-size: 14px;
  line-height: 16px;
}

@mixin label {
	font-family: "Ubuntu-Regular";
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

@mixin Roboto-Regular {
	font-family: "Roboto-Regular";
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}


@mixin text {
	font-family: "Ubuntu-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color : $black;
}

@mixin bold {
	@include text;
  font-weight: 600;
}

@mixin text-button {
	font-family: "Anybody-SemiBold";
  font-weight: normal;
  font-size: 14px;
}
