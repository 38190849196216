.checkbox{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  label{
    margin-right: 7px;
    margin-bottom: 0;
    font-size: 13px;
    cursor: pointer;
  }
}
