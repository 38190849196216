@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }

  .input {
    @include input;
  }

  &.h3 {
    width: 100%;
    .input {
      border-radius: 5px;
      background: $white;
      border: 1px solid rgba(25, 34, 67, 0.3);
      @include title-2;
      text-transform: uppercase;
    }
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

}
