@import '../form-mixin.scss';
.date-picker{

  label{
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.container-input{
      margin-top: 10px;
    }
  }

  .container-input{
    position: relative;
    width: 100%;
    .container-icon{
      display: block;
      position: absolute;
      top: 2px;
      right:0;
      height: 36px;
      width: 36px;
      pointer-events: none;
      color: $primary-color;
      >svg{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
      }
    }

    >div{
      width: 100%;
    }
  }

  .day{
    @include transition;
    color : black;
    &.selected{
      background-color: $primary-color;
      color : white;
    }

    &:not(.selected){
      background-color: transparent;
      &:hover {
        background-color: rgba($primary-color, 0.5);
        color : white;
      }  
    }
  }

  input{
    @include input;
    border-radius: $radius;
    min-height: 40px;
    padding: 8px 10px;
    min-width: 100px;
    background: #FAFBFB;
    outline: none;
    border: 1px solid rgba(79, 60, 200, 0.3);
    color: $black;
    @include label;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    &:focus{
      outline: none;
      border: 1px solid rgba(79, 60, 200, 1);
    }

    &:focus{
      box-shadow: 0 0 0 1px $primary-color;
    }

    &::placeholder {
      font-size: 14px;
      color : #8c8c8c;
    }
  }

}
