@import '../../styles/variables';

.container {
  @include transition;
  @include btn;
  @include text;
  text-decoration: none;
  text-align: left;
  color: $dark;
  padding: 10px;
  border-radius:  4px;
  background: $white;
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 3px;
  display: flex;
  align-items: center;
  .link {
    @include btn;
    @include text;
    align-items: center;
    display: flex;
    text-align: left;
    width: calc(100% - 80px);
    .logo {
      @include flex-center;
      margin-right: 15px;
      border-radius:  4px;
      width: 60px;
      height: 60px;
      @media only screen and (min-width: $sm-screen) {
        width: 106px;
        height: 106px;
      }
      @media only screen and (min-width: $sm-screen) {
        margin-right: 20px;
      }
      p {
        @include text;
        font-size: 36px;
        color: $white;
      }
      img {
        border-radius:  4px;
        object-fit: cover;
        width: 60px;
        height: 60px;
        @media only screen and (min-width: $sm-screen) {
          width: 106px;
          height: 106px;
        }
      }
    }
    h3 {
      padding: 0px;
      font-size: 20px;
      margin: 0px;
      display: block;
    }
    .infos {
      p {
        color: rgba($dark,0.6);
        margin: 5px 0px 5px 0px;
      }
      a {
        @include bold;
        color: $primary-color;
      }
    }
  }
  .star {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      @include btn;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      color: rgba($primary-color, 0.2);
      @media only screen and (min-width: $sm-screen) {
        font-size: 32px;
      }
      &.is-favorite {
        color: $primary-color;
      }
      &:hover {
        color: rgba($primary-color, 0.5);
      }
    }
  }
}