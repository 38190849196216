@import '../../styles/variables.scss';

.container {
  @include page-container;
  padding: 0px;
  overflow: hidden;
  @media only screen and (min-width: $lg-screen) {
    display: flex;
  }
  h3 {
    @include title-2;
    font-size: 20px;
    margin: 0px;
  }
  main {
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow: auto;
    @media only screen and (min-width: $lg-screen) {
      height: 100%;
      width: 65%;
      padding: 30px;
    }
    .header {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .name {
        h2 {
          @include title-3;
          font-size: 36px;
          margin: 0px;
        }
        p {
          margin-top: 20px;
          text-transform: capitalize;
        }
      }
    }
    .tools {
      display: none;
      border-bottom: 1px solid rgba($dark, 0.1);
      padding: 40px 0px;
      @media only screen and (min-width: $lg-screen) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      a {
        width: calc(25% - 10px);
        margin-right: 10px;
        margin-bottom: 10px;
        @include tool;
      }
    }
    .row {
      @media only screen and (min-width: $lg-screen) {
        display: flex;
        justify-content: space-between;
      }
      h2 {
        @include title-3;
        font-size: 20px;
        margin-bottom: 6px;
      }
      .col {
        position: relative;
        @media only screen and (min-width: $lg-screen) {
          width: calc(50% - 10px)
        }
        &.notifs {
          display: none;
          @media only screen and (min-width: $lg-screen) {
            display: block;
          }
          .link {
            color: rgba($dark, 0.6);
            text-decoration: none;
            display: block;
            margin: 10px 0px;
            @media only screen and (min-width: $xl-screen) {
              position: absolute;
              margin: 0px;
              right: 0px;
              top: 20px;
            }
            &:hover {
              text-decoration: underline;
            }
          }
          .no-notifs {
            p {
              margin: 0px;
              color: rgba($dark, 0.6);
            }
            .illu {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 60px 0px;
            }
          }
        }
      }
    }
  }
  aside {
    width: 100%;
    height: 100%;
    background-color: $dark;
    overflow: auto;
    @media only screen and (min-width: $lg-screen) {
      height: 100%;
      width: 35%;
    }
    h3 {
      color: $white;
      margin-bottom: 15px;
    }
    .tasks-container {
      margin-top: 20px;
    }
    .gif {
      width: 100%;
      padding: 20px;
      display: none;
      @media only screen and (min-width: $lg-screen) {
        display: block;
        padding: 30px;
      }
      .image {
        width: 100%;
        height: 260px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 13px;
        margin-bottom: 30px;
        img {
          min-height: 100%;
          min-width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}