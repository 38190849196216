@import '../../styles/variables';

.container {
  color: $white;
  padding: 20px 30px;
  position: relative;
  .close {
    @include btn;
    position: absolute;
    right: 10px;
    top: 13px;
    text-transform: uppercase;
    color: $white;
    font-size: 35px;
  }
  a {
    color: $white;
  }
  h4 {
    max-width: 85%;
    font-size: 18px;
    margin: 0px;
  }
  .company {
    margin: 0px;
  }
  .toggle {
    @include btn;
    @include title-2;
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      @include transition;
      margin: 0px;
      color: $white;
      width: calc(50% - 4px);
      border-bottom: 1px solid transparent;
      padding-bottom: 6px;
      text-align: left;
      opacity: 0.5;
      &:last-child {
        text-align: right;
      }
      &:hover {
        opacity: 1;
      }
      &.active {
        border-bottom: 1px solid $white;
        opacity: 1;
      }
    }
  }
  .archived {
    @include btn;
    color: $white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include text;
    color: $white;
    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
    svg {
      font-size: 22px;
    }
    p {
      margin-left: 10px;
      text-transform: uppercase;
    }
  }
  .tasks {
    position: absolute;
    width: 100%;
    left: 0px;
    padding: 0px 30px 30px 30px;
    overflow-y: auto;
    height: calc(100vh - 200px);
    @include scrollbarWhite;
  }
  .project {
    position: absolute;
    width: 100%;
    left: 0px;
    padding: 0px 30px 30px 30px;
    overflow-y: auto;
    height: calc(100vh - 200px);
    @include scrollbarWhite;
    label {
      opacity: 0.5;
    }
  }
}

.files {
  margin: 10px 0px;
  a {
    @include bold;
    @include transition;
    display: block;
    background-color: rgba($white, 0.15);
    color: $white;
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 5px;
    &:hover {
      background-color: rgba($white, 0.25);
    }
  }
}