@import './mixins.scss';
@import './fonts-style.scss';

$bg-color: #FAFBFB;
$white: #FFFFFF;
$black: #181844;
$dark: #192243;
$dark-lighter: #5F657C;
$primary-color: #181844;
$primary-dark: #3b2b92;
$primary-light: #B5E0DC;
$primary-extra-light: rgba(118, 230, 232, 0.235);

$secondary-color: #237EB1;

$dark-grey:#606060;
$grey:#999999;
$light-grey: #E3E3E3;
$orange: #FF8469;

$text-color-light: #E3E3E3;
$success: #4DCD98;
$danger : red;

$congress-color: #5B2195;
$trips-color: #D87BC3;
$training-color: #D06666;
$field-color: #08A592;
$sick-color: #DFA67E;
$office-color: #aad3f0;

$shadow: 0px 10px 28px #d7d7d7;


$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;
$xxl-screen: 1400px;

$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$small : 13px;
$regular : 16px;
$h2 : 18px;
$h1 : 25px;

$radius: 3px;

@font-face {
	font-family: "Anybody-Bold";
	src: url("../assets/fonts/Anybody-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Anybody-SemiBold";
	src: url("../assets/fonts/Anybody-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Anybody-Regular";
	src: url("../assets/fonts/Anybody-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Ubuntu-Regular";
	src: url("../assets/fonts/Ubuntu-Regular.ttf") format("truetype");
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px $primary-extra-light inset !important;
}