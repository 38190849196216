@import '../../../styles/variables.scss';

/* Customize the label (the container) */ 
.editor {
  position: relative;
  @include transition;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 160px;
  padding: 0px 14px;
  font-size: 14px;
  margin-top: 5px;
  border: 1px solid rgba($dark, 0.4);
  transition: all 0.2s ease-in-out;
  background-color: rgba($white , 0.1);
  // box-shadow: $shadow;
  border-radius: $radius;
  color: $dark;
  @include text;
  margin-bottom: 20px;
  z-index: 0;
  &:focus {
    outline: none;
    border: 1px solid $primary-color;
  }
  a {
    color: $primary-color;
  }
}

.toolbar {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0px;
  margin-top: 10px;
}