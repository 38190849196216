@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-textarea{
  margin: 5px 0px 20px 0px;

  label{
    @include label;
  }

  &.inline{
    @include inline;
  }

  &:not(.inline){
    label+.container{
      margin-top: 10px;
    }
  }

  &.disabled{
    pointer-events: none;
    opacity: .5;
  }

  .input {
    @include input;
    min-height: 120px;
    max-width: 100%;
    &.disabled{
      pointer-events: none;
    }
  }

  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

  .container{
    width: 100%;
    min-width: 250px;



    .count-characters{
      text-align: right;
      font-size: 12px;
      margin: 0;
      padding: 0;
    }

  }





}
