@import '../../styles/variables';

.container {
  @include transition;
  position: fixed;
  height: calc(100vh - 72px);
  top: 72px;
  background-color: $dark;
  right: 0px;
  z-index: 100;
  width: 100%;
  transform: translateX(100%);
  &.active {
    transform: translateX(0);
  }
  @media only screen and (min-width: $lg-screen) {
    width: 369px;
  }
  h1 {
    margin: 0px;
    @include title-2;
    color: $white;
    padding: 20px 20px;
    font-size: 26px;
    line-height: normal;
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    @include btn;
    font-size: 35px;
    color: $white;
  }
  .search {
    padding: 0px 20px 20px 20px;
    input {
      @include text;
      width: 100%;
      border-radius: 6px;
      border: 1px solid #E5E5EC;
      background: #FFF;
      padding: 8px 10px;
      box-shadow: none;
      outline: none;
    }
  }
  .list {
    padding:  0px 20px;
    height: calc(100% - 110px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    .cr {
      @include btn;
      @include text;
      border-radius: 6px;
      border: 1px solid #E5E5EC;
      background: #FFF;
      text-align: left;
      padding: 10px;
      margin-bottom: 8px;
      opacity: 0.6;
      &.active {
        opacity: 1;
      }
      h4, p {
        margin: 0px;
      }
      h4 {

      }
      .infos {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
} 