@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .notifiction {
    @include btn;
    @include tool;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid rgba($dark, 0.1);
    border-radius: 6px;
    position: relative;
    &:hover {
      background-color: rgba($dark, 0.05);
    }
    .new {
      position: absolute;
      top: -6px;
      left: -6px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #B55555;
    }
    .icon {
      min-width: 48px;
    }
    .infos {
      @include text;
      text-align: left;
      h5 {
        margin: 0px;
      }
      p {
        margin: 0px;
        color: rgba($dark, 0.6);
      }
    }
  }
}