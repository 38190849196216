@import '../../styles/variables';

.container {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  .close {
    @include btn;
    position: absolute;
    right: 10px;
    top: 13px;
    text-transform: uppercase;
    color: $white;
    font-size: 35px;
    @media only screen and (min-width: $lg-screen) {
      display: none;
    } 
  }
}

.archived {
  @include btn;
  color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include text;
  color: $white;
  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
  svg {
    font-size: 22px;
  }
  p {
    margin-left: 10px;
    text-transform: uppercase;
  }
}

.list {
  width: 100%;
  .todo {
    margin-bottom: 8px;
    background-color: $bg-color;
    padding: 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      display: flex;
      align-items: center;
      p {
        margin: 0px;
        span {
          color: rgba($dark, 0.4);
        }
      }
      .check {
        @include btn;
        width: 24px;
        height: 24px;
        border: 2px solid rgba($dark, 0.4);
        border-radius: 15px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: transparent;
        &.done {
          background-color: $success;
          border: 2px solid rgba($success, 0.4);
          color: $white;
          &:hover {
            color: transparent;
            border: 2px solid rgba($dark, 0.4);
            background-color: rgba($dark, 0.1);
          }
        }
        &:not(.done) {
          &:hover {
            color: rgba($dark, 0.4);
          }
        }
      }
    }
    .delete {
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: rgba($dark, 0.4);
      &:hover {
        color: $danger;
      }
    }
  }
}

  .create {
    @include btn;
    @include text;
    color: $white;
    float: right;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
    .icon {
      @include transition;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $bg-color;
      width: 26px;
      height: 26px;
      margin-left: 10px;
      color: $dark;
      font-size: 24px;
      border-radius: 6px;
    }
}